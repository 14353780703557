import memepad from "./memepad.json";
import stake from "../../../images/stake.png"
import farm from "../../../images/farm.png"

export const stakeIds = ["mepad2","mepad3"];

//Initial values of the stake, used when metamask is not connected.
//When metamask is connected, these values will update based on the contract.

const subTitleLP = <span>Stake your TRIBEX BUSD LP, <br /> Lock 30 days & Receive an NFT</span>
const subTitleFixedTerm = <span>CLAN is a rewards ecosystem for holders.<br />Hold $CLAN & Earn Rewards.</span>

export const stakingState = {
  mepadTokenContract: null,
  lpTokenContract: null,
  decimals: 9,
  mepadTokens: 0,
  mepad2: {
    stakingContract: null,
    enabled: false,
    pendingReward: 0,
    stakedAmount: 0,
    rewardPerYear: 0,
    totalStakingTokens: 0,
    stakingUrl: 0, // memepad.prefix + memepad.mepad2.stakingAddress + "#code", //Constant, not affected by metamask
    image: stake, //Constant, not affected by metamask
    title: "(DEMO) ⛏️ Fixed Time JOL Staking", //Constant, not affected by metamask
    subTitle: <span>Deposit your JOL, earn more JOL<br />& get whitelisted for RWA sales 🏆</span>, //Constant, not affected by metamask
    symbol: "JOL", //Constant, not affected by metamask
    isCompleted: false, //This value got fetched from contract and became false eventually
    beforeUnlockBtnText: '1 JOL Staked = 1 Whitelist Allocation',
  },
  mepad3: {
    stakingContract: null,
    enabled: false,
    pendingReward: 0,
    stakedAmount: 0,
    rewardPerYear: 0,
    totalStakingTokens: 0,
    stakingUrl: 0, // memepad.prefix + memepad.mepad2.stakingAddress + "#code", //Constant, not affected by metamask
    image: farm, //Constant, not affected by metamask
    title: "(DEMO) 🌱 JOL/ETH LP Farming", //Constant, not affected by metamask
    subTitle: <span>Provide JOL/ETH on Uniswap<br />& stake your JOL/ETH LPs 💧</span>, //Constant, not affected by metamask
    symbol: "JOL/ETH LP", //Constant, not affected by metamask
    isCompleted: false, //This value got fetched from contract and became false eventually
    beforeUnlockBtnText: 'Add DEX Liquidity & Earn Huge APY',
  },
};
